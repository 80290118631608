.app__sauna-content {
   display: flex;
   flex-direction: column;
   width: 100%;
}
.app__sauna-content_main-text {
   margin: 2rem 0;
}
.app__sauna-content_minor-text {
   font-size: 19px;
   font-weight: bold;
   color: var(--color-prussian-blue);
}
.slider {
   position: relative;
 }
 .slider::before {
   content: '';
   position: absolute;
   top: 0;
   left: 100%;
   width: 10000%;
   height: 100%;
 }
 .carousel .slide {
   max-height: 636px;
 }
 .carousel .slide img{
   max-width: 636px;
   vertical-align: bottom;
 }
 .carousel .thumbs-wrapper {
   margin: 20px;
   overflow: hidden; }
 .carousel .thumb {
   margin-right: 6px;
    border: 2px solid var(--color-prussian-blue);
    padding: 0px; 
    
   }
   .carousel .thumb:focus {
     border: 2px solid var(--color-indigo-blue);
     outline: none; }
   .carousel .thumb.selected, .carousel .thumb:hover {
     border: 2px solid var(--color-ivory); }
   .carousel .thumb img {
      width: 100%;
      }
.carousel {
   max-width: 636px;
}

 