.app__events {
   display: flex;
   flex-direction: row;
}
.app__events-content {
   align-self: flex-start;
   width: 100%;
   align-items: flex-end;
   justify-items: flex-end;
   flex-direction: column;
}
.app__events-content h1 {
text-align: center;
}
.app__events-content_permanent {
   flex: 1;
   display: flex;
   justify-content: flex-end;
   align-items: center;
   flex-direction: column;
}
.app__events-content_permanent img {
   align-self: flex-start;
}
.app__events-content_general {
   flex: 1;
   display: flex;
   justify-content: flex-start;
   align-items: center;
   flex-direction: column;
}
.app__events-content_general img {
   align-self: flex-start;
}
.app__events-content_general p{
margin: 2rem 0;
}
.app__events-content_permanent p{
   margin: 2rem 0;
   }
.app__facebook_container {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   align-self: stretch;
   min-height: 500px;
}
.app__facebook_iframe {
   width: 340px;
   height: 500px;
   border: none;
   overflow: hidden;
}
@media screen and (max-width: 1150px) { 
   .app__events{
      flex-direction: column;
   }
   .app__facebook_container {
      padding-top: 2rem;
   }
}
@media screen and (max-width: 1150px) { 
   .app__facebook_iframe{
      width: 100%;
   }
   
}