.app__links {
   padding: 2rem 4rem;
   background: var(--color-prussian-blue);

   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: flex-start;
   margin-top: 5rem;
}
.app__links-link {
   flex: 1;
   margin: 1rem;
   text-align: center;
}
.app__links-link img{
   height: 77px;
   margin-bottom: 0.75rem;
}

@media screen and (max-width: 1150px) {
   .app__links {
      align-items: center;
      flex-direction: column;
      padding: 0;
   }
   .app__footer-links_link{
   margin: 2rem 0;
   width: 100%;
}
}
@media screen and (max-width: 350px) {
   .app__footer-links_logo img {
      width: 80%;
   }
}