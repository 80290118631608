
@import url('https://fonts.googleapis.com/css2?family=Poiret+One&family=Raleway:wght@300;400&display=swap');

:root {
  --font-base: 'Verdana', sans-serif;
  --font-alt: 'Raleway', sans-serif;
  --font-fancy: 'Poiret One', cursive;

  --color-ash-gray: #B4B8AB;
  --color-prussian-blue: #153243;
  --color-indigo-blue: #284B63;
  --color-ivory: #F4F9E9;
  --color-burnt-orange: #BA5624;
  --color-black: #000000
}

* {
   box-sizing: border-box;
   padding: 0;
   margin: 0;
   scroll-behavior: smooth;
 }
 
 a {
   color: unset;
   text-decoration: none;
 }
 
 .slide-bottom {
   -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
 }
 
 @-webkit-keyframes slide-bottom {
   0% {
     -webkit-transform: translateY(-25%);
             transform: translateY(-25%);
   }
   100% {
     -webkit-transform: translateY(0);
             transform: translateY(0);
   }
 }
 
 @keyframes slide-bottom {
   0% {
     -webkit-transform: translateY(-25%);
             transform: translateY(-25%);
   }
   100% {
     -webkit-transform: translateY(0);
             transform: translateY(0);
   }
 }