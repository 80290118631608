
.app__navbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-prussian-blue);
  padding: 1rem 2rem;
  position: fixed;
  z-index: 5;
}
.app__navbar-logo {
display: flex;
justify-content: flex-start;
align-items: center;
cursor: pointer;

}
.app__navbar-logo img {
  width: 85px;
}
.app__navbar-links {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  list-style: none;
}
.app__navbar-links li {
  margin: 0 1rem;
cursor: pointer;
}
.app__navbar-links li:hover{
 color: var(--color-ash-gray);
}
.app__navbar-lang{
font-size: x-large;
display: flex;
justify-content: flex-end;
align-items: center;
}
.app__navbar-divider {
  width: 1px;
  height: 30px;
  background-color: var(--color-ivory);
}
.app__navbar-divider:hover {
  cursor:unset;
}
.app__navbar-smallscreen {
  display: none;
}
.app__navbar-smallscreen_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--color-prussian-blue);
  transition: 0.5s ease;

  flex-direction: column;
  z-index: 5;
}

.app__navbar-smallscreen_overlay .overlay_close {
  font-size: 27px;
  color: var(--color-ivory);
  cursor: pointer;

  position: absolute;
  top: 20px;
  right: 20px;
}
.app__navbar-smallscreen_links {
  list-style: none;
}
.app__navbar-smallscreen_links li{
  margin: 2rem;
  cursor: pointer;
  color: var(--color-ivory);
  font-size: 2rem;
  text-align: center;
  font-family:var(--font-alt) 
}
.app__navbar-smallscreen_links li:hover {
  color: var(--color-ash-gray);
}

@media screen and (max-width: 2000px) {
  .app__navbar-logo img {
    width: 100px
  }
}


@media screen and (max-width: 1150px) {
  .app__navbar-links{
    display: none;
  }
 .app__navbar-smallscreen {
  display: flex;
 }
 @media screen and (max-width: 650px) {
  .app__navbar {
    padding: 1rem;
  }
  .app__navbar-logo img{
    width: 50px;
  }
  .app__navbar-lang a {
    display: none;
  }
}
}